import { environment } from './../../../../environments/enviroment';
import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  IRequestNewGatewayData,
  IRequestNewGatewayResponse,
} from '../interfaces/requestNewGateway';
import { StatusCounts } from '../../../layouts/components/side-menu/assets/live-point';

@Injectable({
  providedIn: 'root',
})
export class RequestGatewayService {
  private _HttpClient = inject(HttpClient);
  public statusCounts = new BehaviorSubject<StatusCounts>({} as StatusCounts);


  // get all gateways requests
  getAllGatewayRequests( page: number, limit: number, name?: string, email?: string ): Observable<IRequestNewGatewayResponse> {
    let optionalParams = [];
    if (email) optionalParams.push(`&email=${email}`);
    if (name) optionalParams.push(`&name=${name}`);
    return this._HttpClient.get<IRequestNewGatewayResponse>( `${environment.apiUrl}/v1/new-gateway/request-new-gateway?page=${page}&limit=${limit}${optionalParams.join('').split(',').toString()}` );
  }
  // get gateway request
  getRequest(id: string): Observable<IRequestNewGatewayData> {
    return this._HttpClient.get<IRequestNewGatewayData>( `${environment.apiUrl}/v1/new-gateway/request-new-gateway/${id}` );
  }

  getNewGatewayRequestCounts(): Observable<StatusCounts> {
    return this._HttpClient.get<StatusCounts>(`${ environment.apiUrl }/v1/new-gateway/removed-counts`);
  }
}
